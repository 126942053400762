<template>

<!-- 校园筛查 筛查前设置/ 修改信息页面 -->
  <div class="page">
    <div class="school-form">
      <el-form label-width="168px" :model="ruleForm" :rules="rules" ref="ruleForm" size="mini" class="school-ruleForm el-form">
        <el-form-item label="学校所在地：" prop="placeCode">
          <el-cascader
            :options="options"
            :props="props"
            v-model="selectedOptions"
            @change="handleChange"
            @active-item-change="handleChange"
            v-bind="$attrs"
            :clearable="clearable"
            :filterable="filterable"
            :change-on-select="changeOnSelect"
            :disabled="disabled"
            :show-all-levels="showAllLevels"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="学校所属社区(街道）：" prop="address">
          <el-input  maxlength="50" v-model="ruleForm.address" placeholder="请填写学校所属社区"></el-input>
        </el-form-item>
        <el-form-item  maxlength="11" label="学校名称：" prop="schoolName">
          <el-input v-model="ruleForm.schoolName" placeholder="请填写学校名称"></el-input>
        </el-form-item>
        <el-form-item  label="联系人" prop="principalName">
          <el-input maxlength="11" v-model="ruleForm.principalName" placeholder="请填写联系人名称"></el-input>
        </el-form-item>
        <el-form-item  maxlength="11" label="联系电话" prop="principalPhone">
          <el-input v-model="ruleForm.principalPhone" placeholder="请填写负责人联系方式"></el-input>
        </el-form-item>
        <el-form-item label="学校类型：" prop="schoolType">
          <el-select v-model="ruleForm.schoolType" placeholder="请选择学校类型">
            <el-option
              v-for="item in natureOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校性质：" prop="schoolNature">
          <el-select v-model="ruleForm.schoolNature" placeholder="请选择学校性质">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      <el-form-item >
        <el-button type="primary" @click="submitForm('ruleForm')">修改</el-button>
        <el-button type="return"  @click="returnPage()">返回</el-button>
      </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { PlaceCode } from '../../../static/js/area.js'
import schoolService from '@/globals/service/detection/school.js'

export default {

  data () {
    return {
      options: [],
      selectedOptions: [],
      keyPath: null,
      // 是否可搜索选项
      filterable: false,
      // 是否允许选择任意一级的选项 默认值false
      changeOnSelect: false,
      // 是否禁用
      disabled: false,
      clearable: true,
      // 输入框中是否显示选中值的完整路径
      showAllLevels: true,
      props: {
        value: 'value',
        label: 'name',
        children: 'children',
        disabled: 'disabled'
      },

      natureOptions: [
        { value: '0', label: '幼儿园' },
        { value: '1', label: '小学' },
        { value: '2', label: '初中' },
        { value: '3', label: '高中' },
        { value: '4', label: '大学' }
      ],
      typeOptions: [
        { value: 0, label: '公办' },
        { value: 1, label: '民办' }
      ],
      ruleForm: {
        schoolId: '',
        placeCode: [],
        address: '',
        schoolName: '',
        schoolType: '',
        schoolNature: '',
        principalName: '',
        principalPhone: null,
        oldPrincipalPhone: null
      },
      rules: {
        placeCode: [
          { required: true, message: '请选择学校所在地', trigger: 'change' }
        ],
        schoolName: [
          { required: true, message: '请填写学校名称', trigger: 'blur' }
        ],
        schoolType: [
          { required: true, message: '请选择学校类型', trigger: 'change' }
        ],
        schoolNature: [
          { required: true, message: '请选择学校性质', trigger: 'change' }
        ],
        principalName: [
          { required: true, message: '请输入学校联系人', trigger: 'change' }
        ],
        principalPhone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { required: true, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '目前只支持中国大陆的手机号码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  async mounted () {
    const row = this.$route.query
    const placeCode = [row.provinceId, row.cityId, row.areaId]
    this.keyPath = placeCode
    this.ruleForm = {
      schoolId: row.id,
      placeCode: placeCode,
      address: row.address,
      schoolName: row.schoolName,
      schoolNature: parseInt(row.schoolNature),
      schoolType: row.schoolType,
      principalName: row.principalName,
      principalPhone: row.principalPhone,
      oldPrincipalPhone: row.principalPhone

    }
    await this.find()
  },
  created () {
  },
  methods: {
    goTo (path, query) {
      this.$router.push({ path: path, query: query })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // const loading = this.$loading({
          //   lock: true,
          //   text: '加载中……',
          //   background: 'rgba(0, 0, 0, 0.7)'
          // })
          const that = this
          const ruleForm = this.ruleForm
          const formData = {
            schoolId: ruleForm.schoolId,
            address: ruleForm.address,
            provinceId: ruleForm.placeCode[0] || '',
            cityId: ruleForm.placeCode[1] || '',
            areaId: ruleForm.placeCode[2] || '',
            schoolName: ruleForm.schoolName,
            schoolNature: ruleForm.schoolNature,
            schoolType: ruleForm.schoolType,
            principalName: ruleForm.principalName,
            principalPhone: ruleForm.principalPhone,
            oldPrincipalPhone: ruleForm.oldPrincipalPhone
          }
          schoolService.update(formData).then(res => {
            this.$message({
              message: res.errMsg,
              type: 'success',
              duration: 1000,
              onClose: function () {
                // loading.close()
                that.$router.push({ path: '/detection/schoolManagement' })
              }
            })
          })
        } else {
          this.$message.error('请填入相应信息！')
          return false
        }
      })
    },
    returnPage () {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/login' })
        return false
      } else {
        this.$router.push({ path: '/detection/schoolManagement' })
      }
    },
    async find () {
      const options = PlaceCode()
      if (Array.isArray(options)) {
        this.options = await this.newOptions(options)
        this.selectedOptions = this.keyPath
      } else {
        this.options = []
        this.selectedOptions = []
      }
    },
    /**
     * 选择
     */
    handleChange (newValue) {
      // let code = ''
      if (newValue.length > 0) {
        // code = newValue[newValue.length - 1]
      }
      // 区域代码
      // let areaCode = code
      if (newValue.length === 3) {
        const level1 = newValue[0]
        if ('110000|120000|310000|500000'.indexOf(level1) !== -1) {
          // 直辖市的区域代码不变
        } else {
          // 省
          // areaCode = newValue[1] // 省的县级市的区域代码设置为地级市的行政区划代码
        }
        this.ruleForm.placeCode = newValue
      }
    },
    /**
     * 递归处理
     * 将所有的id更换为string类型
     */
    newOptions (options) {
      options.forEach(item => {
        item.value = String(item.value)
        if (item.children) {
          this.newOptions(item.children)
        }
      })
      return options
    }

  }

}
</script>
<style lang="scss" scoped>
.school-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px 0;
  box-sizing: border-box;
  max-width: 500px
}
.header-container{
  width: 100%;
  height: 43px;
  border-bottom: 1px solid #f5f5f5;
  .header-section{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .route-name{
      line-height: 43px;
      font-size: 12px;
      color: #51B25F;
    }
    .button-section{
      display: flex;
      align-items: center;
    }
  }
}
.page{
    padding: 20px 10px;
    min-height: 100%;
    background-color: #fff;
    color: #333;
  }
  .red{
    color: #f50e0e;
  }
</style>
